import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { AppState } from '@core/store';
import { isNullOrUndefined } from '@fluxys/core';
import { FlxValidatedResult, hasErrors } from '@fluxys/gsmart/rule-validation';
import { FlxWarningDialogService } from '@fluxys/gsmart/warning-dialog';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RegisterCommandResultViewModel, VehiclePartType, VehiclePartTypeLiteral } from '@shared/models';
import { notNullOrUndefined } from '@shared/rxjs/operators';
import { TruckValidators } from '@shared/validators/truck.validators';
import { MenuItem, SelectItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { valueChanges } from 'psm5-web';
import { Observable, combineLatest, distinctUntilChanged, filter, lastValueFrom, map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { UnitCode } from 'unit-codes';

import { LoadingSide, LoadingSideLiteral, VehiclePartFormData, VehiclePartParametersViewModel } from '../../models';
import { VehicleDocument } from '../../../shared/models/vehicle-document';
import { VehiclePartParametersService, VehiclePartService } from '../../services';
import { VehicleFormMode, actions, selectors } from '../../store';

import { VehiclePartDialogService } from './vehicle-part-dialog.service';
import { UserService } from '@core/services/user.service';

@UntilDestroy()
@Component({
  selector: 'trkmgr-vehicle-part-dialog',
  templateUrl: './vehicle-part-dialog.component.html',
  styleUrls: ['./vehicle-part-dialog.component.scss'],
})
export class VehiclePartDialogComponent {
  readonly cancelButtonLabel$: Observable<string>;
  readonly vehicleSectionTitleLabel$: Observable<string>;
  readonly formData$: Observable<VehiclePartFormData<Date>>;
  readonly mode$: Observable<VehicleFormMode>;
  readonly vehiclePartType$: Observable<VehiclePartTypeLiteral | null | undefined>;
  readonly isLoadingPart$: Observable<boolean>;
  readonly displayExpiryDate$: Observable<boolean>;
  readonly mainAction$: Observable<MenuItem>;
  readonly subActions$: Observable<MenuItem[] | undefined>;
  readonly transporters$: Observable<SelectItem<number>[]>;
  readonly countries$: Observable<SelectItem[]>;
  readonly vehiclePartTypes$: Observable<SelectItem[]>;
  readonly statuses$: Observable<SelectItem[]>;
  readonly maxLoadingVolumePct$: Observable<number>;
  readonly vehiclePartParams$: Observable<VehiclePartParametersViewModel>;
  vehicleDocuments$: Observable<VehicleDocument[]> | null = null;
  readonly saveValidationContext = 'save-vehicle-part';
  readonly saveDocumentsValidationContext = 'save-documents';
  readonly loadingSideOptions: SelectItem[];
  readonly form: UntypedFormGroup;
  readonly units = UnitCode;
  vehicleDocuments: VehicleDocument[] = [];
  hasDocumentsSection = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: DynamicDialogRef,
    private readonly warningDialogService: FlxWarningDialogService,
    private readonly fb: UntypedFormBuilder,
    private readonly translate: TranslocoService,
    private readonly vehiclePartParametersService: VehiclePartParametersService,
    private readonly vehiclePartService: VehiclePartService,
    private readonly vehiclePartDialogService: VehiclePartDialogService,
    userService: UserService
  ) {
    this.form = this.vehiclePartDialogService.createForm(this.fb);

    this.mode$ = this.store.select(selectors.vehicleParts.formMode) as Observable<VehicleFormMode>;
    this.formData$ = this.store.select(selectors.vehicleParts.formData).pipe(notNullOrUndefined());
    this.formData$.pipe(untilDestroyed(this)).subscribe((data) => this.form.patchValue(data));
    this.vehiclePartType$ = this.formData$.pipe(map((formData) => formData.vehiclePartType));
    this.countries$ = this.store.select(selectors.countries) as Observable<SelectItem[]>;
    this.displayExpiryDate$ = this.store.select(selectors.vehicleParts.displayExpirationDate) as Observable<boolean>;

    userService.loadUserInfo().subscribe((x) => {
      this.hasDocumentsSection = x.permissions.includes('vehicle-part-documents-manage');
    });

    this.vehicleDocuments$ = this.store.select(selectors.vehicleParts.vehicleDocuments) as Observable<VehicleDocument[]>;

    this.isLoadingPart$ = this.vehiclePartType$.pipe(
      map((type) => type === VehiclePartType.LNGContainer || type === VehiclePartType.RoadTanker)
    );
    this.transporters$ = this.store.select(selectors.transporters).pipe(
      filter((ts) => ts !== undefined),
      map((ts) => (ts ?? []).map((t) => ({ label: t.name, value: t.businessPartyId })))
    );

    const actions$ = this.initializeActions();
    this.mainAction$ = actions$.pipe(map(([mainAction]) => mainAction));
    this.subActions$ = actions$.pipe(map(([_, ...subActions]) => (subActions.length !== 0 ? subActions : undefined)));

    // Some gymnastic so the action is not sent when the form loads. Could be improved ?
    combineLatest([valueChanges<number>(this.form, 'vehicleInformation.statusId'), this.formData$])
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(([newStatusId, formData]) => {
        if (newStatusId !== formData.vehicleInformation.statusId) this.store.dispatch(actions.vehicleParts.statusChanged({ newStatusId }));
      });

    this.vehiclePartParams$ = this.vehiclePartParametersService.getVehiclePartBusinessParameters().pipe(
      tap((params) => {
        this.setQuantityConstraint('vehicleInformation.weight', params.minWeight, params.maxWeight);
        this.setQuantityConstraint('lngcontainer.totalLoadingVolume', params.minTotalLngVolume, params.maxTotalLngVolume);
        this.setQuantityConstraint('roadtanker.totalLoadingVolume', params.minTotalLngVolume, params.maxTotalLngVolume);
        this.setQuantityConstraint('lngcontainer.maxFillingPercentage', params.minFillingPercentage, params.maxFillingPercentage);
        this.setQuantityConstraint('roadtanker.maxFillingPercentage', params.minFillingPercentage, params.maxFillingPercentage);
        this.setQuantityConstraint('lngcontainer.maxOperatingPressure', params.minOperatingPressure, params.maxOperatingPressure, true);
        this.setQuantityConstraint('roadtanker.maxOperatingPressure', params.minOperatingPressure, params.maxOperatingPressure, true);
      })
    );

    // This percentage being only a visual indicator and having no use in other parts of the application, its state is not being kept in the store.
    this.maxLoadingVolumePct$ = this.vehiclePartType$.pipe(
      filter((vehiclePartType) => {
        if (vehiclePartType === null || vehiclePartType === undefined) return false;
        if (!this.form.get(`${vehiclePartType.toLowerCase()}.maxFillingPercentage`)) return false;
        if (!this.form.get(`${vehiclePartType.toLowerCase()}.totalLoadingVolume`)) return false;
        return true;
      }),
      switchMap((vehiclePartType) =>
        combineLatest([
          valueChanges<number>(this.form, `${(vehiclePartType as VehiclePartType).toLowerCase()}.maxFillingPercentage`, true),
          valueChanges<number>(this.form, `${(vehiclePartType as VehiclePartType).toLowerCase()}.totalLoadingVolume`, true),
        ]).pipe(map(([maxFillingPct, totalLoadingVolume]) => (totalLoadingVolume * maxFillingPct) / 100))
      )
    );

    this.mode$
      .pipe(
        mergeMap((mode) =>
          this.vehiclePartType$.pipe(
            filter((vehiclePartType) => !!vehiclePartType),
            tap((vehiclePartType) => this.vehiclePartDialogService.configureForm(this.form, vehiclePartType, mode))
          )
        ),
        untilDestroyed(this)
      )
      .subscribe();

    this.vehiclePartTypes$ = this.translate.selectTranslateObject<HashMap<string>>('enums.vehicle-part-type').pipe(
      take(1),
      map((translations) => [
        { label: translations.LNGContainer, value: VehiclePartType.LNGContainer },
        { label: translations.Chassis, value: VehiclePartType.Chassis },
        { label: translations.Tractor, value: VehiclePartType.Tractor },
        { label: translations.RoadTanker, value: VehiclePartType.RoadTanker },
      ])
    );

    this.statuses$ = combineLatest([this.store.select(selectors.vehicleParts.statuses), this.isLoadingPart$]).pipe(
      map(([vehiclePartStatuses, isLoadingPart]) => {
        const statuses = [] as SelectItem[];
        const filteredVehiclePartStatuses = isLoadingPart
          ? vehiclePartStatuses.filter((f) => f.isApplicableForLoadableVehiclePart)
          : vehiclePartStatuses.filter((f) => f.isApplicableForUnloadableVehiclePart);
        filteredVehiclePartStatuses.forEach((status) => {
          statuses.push({ label: status.description, value: status.id });
        });

        return statuses;
      })
    );

    this.cancelButtonLabel$ = this.mode$.pipe(
      map((mode) => (mode === 'view' ? 'app.modules.vehicles.shared.buttons.close' : 'app.modules.vehicles.shared.buttons.cancel'))
    );

    this.vehicleSectionTitleLabel$ = this.formData$.pipe(
      mergeMap((formData) =>
        this.translate
          .selectTranslate<string>(`app.modules.vehicles.components.vehicle-dialog.vehicle-title.${formData.vehiclePartType}`)
          .pipe(take(1))
      )
    );

    this.loadingSideOptions = Object.keys(LoadingSide)
      .map((key) => key as LoadingSideLiteral)
      .map((enumMember) => ({
        label: this.translate.translate(`enums.loading-side.${LoadingSide[enumMember]}`),
        value: LoadingSide[enumMember],
      }));
  }

  getForm(path: string): UntypedFormGroup {
    return this.form.get(path) as UntypedFormGroup;
  }

  async save(isCreate = true, isDocuments = false): Promise<void> {
    if (!this.form.valid) return;

    const formValue = this.form.getRawValue() as VehiclePartFormData<Date>;

    if (isNullOrUndefined(formValue.vehicleInformation.owner)) {
      const brMessage = this.translate.translate('rule-validations.messages.TRUCKMANAGER-BR-1000');
      const result = await lastValueFrom(this.warningDialogService.confirm({ message: brMessage }));
      if (!result) return;
    }

    if (isCreate) {
      const command = this.vehiclePartDialogService.mapToRegister(formValue);
      this.vehiclePartService
        .registerVehiclePart(command, this.saveValidationContext)
        .subscribe((response: RegisterCommandResultViewModel) => {
          if (!hasErrors(response)) {
            if (this.hasDocumentsSection) {
              this.vehicleDocuments.forEach((doc) => (doc.vehiclePartId = response.registeredEntityId));
              this.vehiclePartService.saveVehicleDocuments(this.vehicleDocuments, this.saveDocumentsValidationContext).subscribe((resp) => {
                if (!hasErrors(resp)) {
                  this.close();
                  this.store.dispatch(actions.vehicleParts.overview.load());
                }
              });
            } else {
              this.close();
              this.store.dispatch(actions.vehicleParts.overview.load());
            }
          }
        });
    } else if (!isDocuments) {
      const command = this.vehiclePartDialogService.mapToConfigure(formValue);
      const saveVehiclesObs = this.hasDocumentsSection
        ? this.vehiclePartService.saveVehicleDocuments(this.vehicleDocuments, this.saveDocumentsValidationContext)
        : of({});
      combineLatest([this.vehiclePartService.configureVehiclePart(command, this.saveValidationContext), saveVehiclesObs]).subscribe(
        (results) => {
          if (!hasErrors(results[0]) && !hasErrors(results[1])) {
            this.vehiclePartService.updateStatus(formValue.vehiclePartId ?? 0).subscribe((result) => this.closeIfValid([result]));
          }
        }
      );
    } else {
      this.vehiclePartService.saveVehicleDocuments(this.vehicleDocuments, this.saveDocumentsValidationContext).subscribe((result) => {
        if (!hasErrors(result)) {
          this.vehiclePartService.updateStatus(formValue.vehiclePartId ?? 0).subscribe((r) => this.closeIfValid([r]));
        }
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  openDocument(id: number): void {
    this.vehiclePartService.openVehicleDocument(id).subscribe((x) => window.open(window.URL.createObjectURL(x)));
  }

  private closeIfValid(results: FlxValidatedResult[]): void {
    const hasError = results.some((r) => hasErrors(r));
    if (!hasError) {
      this.close();
      this.store.dispatch(actions.vehicleParts.overview.load());
    }
  }

  private initializeActions(): Observable<MenuItem[]> {
    return combineLatest([
      this.store.select(selectors.vehicleParts.formCanDelete),
      this.store.select(selectors.vehicleParts.formCanEdit),
      this.store.select(selectors.vehicleParts.formCanEditDocumentsOnly),
      this.store.select(selectors.vehicleParts.formId),
    ]).pipe(
      map(([canDelete, canEdit, canEditDocumentsOnly, id]) => {
        const commands: MenuItem[] = [];
        if (canEdit) {
          commands.push({
            label: 'app.modules.vehicles.components.vehicle-dialog.buttons.edit',
            command: () => this.dialogRef.close(id),
          });
        } else if (canEditDocumentsOnly) {
          commands.push({
            label: 'app.modules.vehicles.components.vehicle-dialog.buttons.editDocuments',
            command: () => this.store.dispatch(actions.vehicleParts.overview.editDocuments({ id: id ?? 0 })),
          });
        }
        if (canDelete && id) {
          commands.push({
            label: 'app.modules.vehicles.shared.buttons.delete',
            command: () => this.store.dispatch(actions.vehicleParts.delete({ id, validationContext: 'save-vehicle-part' })),
          });
        }
        return commands;
      })
    );
  }

  private setQuantityConstraint(controlPath: string, min: number, max: number, optional?: boolean): void {
    const control = this.form.get(controlPath);
    const validators = optional
      ? TruckValidators.validQuantity(min, max)
      : Validators.compose([Validators.required, TruckValidators.validQuantity(min, max)]);

    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }
}
